import { useEffect, useState } from "react";
import logo from "./assets/img/logo-samu-academy.png";

const App = () => {
  const [valid, setValid] = useState(false);
  const [inputTop, setInputTop] = useState("");

  useEffect(() => {
    if (
      typeof inputTop === "string" &&
      ["weekitdemais", "weekit"].includes(inputTop.toLowerCase())
    )
      setValid(true);
  }, [inputTop]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        alt="Samu Academy"
        src={logo}
        style={{
          width: "10vw",
          minWidth: "200px",
          WebkitUserSelect: "none",
          marginBottom: "3em",
        }}
      />
      {!valid ? (
        <input
          value={inputTop}
          onChange={(e) => setInputTop(e.target.value)}
          placeholder="Insira seu código secreto"
          style={{ textAlign: "center", padding: "0.6em" }}
        />
      ) : (
        <a
          href="https://app.getresponse.com/site2/14c315a8ba51f816936fb17110d0e63e/?u=QMRQr&webforms_id=hKyZW"
          target="blank"
          style={{
            color: "black",
            textDecoration: "none",
            textTransform: "uppercase",
          }}
        >
          Clique aqui
        </a>
      )}
    </div>
  );
};

export default App;
